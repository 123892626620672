<template>
  <div>
<!--    <el-page-header @back="goBack" content="商品列表"></el-page-header>-->
    <!--  面包屑  -->

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-input placeholder="请输入搜索内容" v-model="querInfo.g_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <!--          <el-button type="primary" @click="uplodstock">库存导入</el-button>-->
<!--          <span>选择商品分类：</span>-->
          <!--    选择商品分类的级联选择框      -->
          <el-cascader v-model="selectefKeys" :options="catelist" clearable  placeholder="请选择商品分类"
                       :props="cascaderProps" @change="parentCateChanged"></el-cascader>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
<!--          <el-select class="line_select" v-model="querInfo.wid" @change="getGoodsList"-->
<!--                     placeholder="请选择" clearable filterable>-->
<!--            <el-option-->
<!--                v-for="item in warehouselist"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
          <el-cascader
              v-model="warehouseselect" clearable placeholder="请选择仓库"
              :options="warehouselist"
              :props="warehouseProps"
              @change="warehousehandleChange"></el-cascader>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-select class="line_select" v-model="querInfo.ga_data_type" @change="dataTypeGoodschange"
                     placeholder="请选择添加方式" clearable filterable>
            <el-option
                v-for="item in datatypelist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}" :xl="{span:24,offset:0}">
          <el-button type="primary" @click="goAddGoods" icon="el-icon-document-add" v-if="is_add">添加</el-button>
          <el-button type="primary" @click="uplodExcel" icon="el-icon-upload2" v-if="is_import">上传</el-button>
          <el-button type="primary" @click="exportExcel" icon="el-icon-download" v-if="is_export">导出</el-button>
        </el-col>
      </el-row>
      <!--   用户列表区域   -->
      <el-table :data="goodslist"   stripe v-loading="loading">
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="商品编码" prop="ga_code" min-width="100px" show-overflow-tooltip></el-table-column>
<!--        <el-table-column label="主图" width="105px">-->
<!--          <template slot-scope="scope">-->
<!--            <img :src="scope.row.ga_image" alt="" style="width: 80px;height: 80px;">-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="商品名称" prop="g_name" min-width="120px" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品规格" prop="ga_name" min-width="120px" show-overflow-tooltip>

        </el-table-column>
        <el-table-column label="单位" prop="gu_name" min-width="50px" ></el-table-column>
        <el-table-column label="市场价" prop="ga_market_price" min-width="70px" show-overflow-tooltip></el-table-column>
        <el-table-column label="成本价" prop="ga_cost_price" min-width="70px" show-overflow-tooltip></el-table-column>
        <el-table-column label="库存数量" min-width="50px">
          <template slot-scope="scope">
            <el-tooltip  placement="top">
              <div slot="content" v-for="(items,index) in scope.row.goodsattrwarehouse" :index="index" :key="index">
                {{items.w_name}} ({{items.wp_name}}) {{items.gawr_stock}} <br/>
              </div>
              <span>{{scope.row.ga_stock}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="安全库存" prop="ga_security_stock" width="50px"></el-table-column>
<!--        <el-table-column label="开始时间" prop="ga_start_date"></el-table-column>-->
<!--        <el-table-column label="结束时间" prop="ga_end_date"></el-table-column>-->
        <el-table-column label="状态" prop="ga_status" width="70px">
          <template slot-scope="scope">
            <!--{{scope.row.m_status}}-->
            <el-switch v-model="scope.row.ga_status" @change="goodsStateChanged(scope.row)" :disabled="is_editStatus"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="230px" fixed="right">
          <template slot-scope="scope">
            <!--添加保质期-->
            <el-tooltip effect="dark" content="保质期" placement="top" :enterable="true" v-if="is_goods_dategoods">
              <el-button type="primary" size="mini" icon="el-icon-date"
                         @click="dategoods(scope.row.ga_id)"></el-button>
            </el-tooltip>

            <!--添加规格-->
            <el-tooltip effect="dark" content="添加规格" placement="top" :enterable="true" v-if="is_add">
              <el-button type="primary" size="mini" icon="el-icon-document-add"
                       @click="addskugoods(scope.row.ga_id)" ></el-button>
            </el-tooltip>
            <!-- 编辑 -->
            <el-button type="primary"  size="mini" v-if="is_edit" icon="el-icon-edit"
                       @click="editgoods(scope.row.ga_id)"></el-button>
            <!--删除-->
            <el-button type="danger"  size="mini" autofocus v-if="is_del" icon="el-icon-delete-solid"
                       @click="removeGoodsById(scope.row.ga_id)"></el-button>

          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </el-card>

  </div>
</template>
<script>
import XLSX from 'xlsx'

export default {
  data() {
    return{
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        g_name:'',
        g_cid:'',
        w_id:'',
        wp_id:''
      },
      total:0,
      //页面权限
      GoodsJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      is_editStatus:true,
      is_import:false,
      is_export:false,
      is_goods_dategoods:false,
      //表格加载
      loading:true,
      goodslist:[],
      //warehouselist 仓库列表
      warehouselist:[],
      //选中的父级分类的ID数组
      selectefKeys: [],
      //商品分类数据
      catelist: [],
      //指定级联选择器的配置对象
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'c_id',
        label: 'c_name',
        children: 'children'
      },
      datatypelist:[
        {id:1,name:'手动添加'},
        {id:2,name:'自动添加'}
      ],
      warehouseProps:{
        expandTrigger: 'hover',
        checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'
      },
      warehouseselect:[],
    }
  },
  created() {
    this.getGoodsList()
    this.getGoodsJurisdiction()
    this.getCateList()
    this.getwarehouselist()
  },
  // beforeRouteEnter(to, from, next){
  //   // console.log(from)
  //   if(from.name=='addgoods'){
  //     to.meta.isBack=false;
  //     //判断是从哪个路由过来的，
  //   }
  //   next();
  // },
  activated() {
    // if(!this.$route.meta.isBack){
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      this.getGoodsList();
      this.getGoodsJurisdiction()
      this.getCateList()
      this.getwarehouselist()
    // }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    // this.$route.meta.isBack=false
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    //获取权限接口
    async getGoodsJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.CateJurisdiction=res.data
      this.CateJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }else if(item.m_path=='import'){
          this.is_import=true
        }else if(item.m_path=='export'){
          this.is_export=true
        }else if(item.m_path=='goods/dategoods'){
          this.is_goods_dategoods=true
        }else if(item.m_path=='editStatus'){
          this.is_editStatus=false
        }
      })
    },
    //获取商品
    async getGoodsList(){
      this.loading=true
      const {data: res} = await this.$http.get('goods/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // res.data.data.forEach(irem=>{
      //   if (item.ga_status==1)
      // })
      // 把数据列表 赋值给catelist
      this.goodslist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },
    //获取仓库树结构
    async getwarehouselist(){
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.warehouselist = res.data
    },
    //获取分类
    async getCateList() {
      const {data: res} = await this.$http.get('category/getMainList/3')
      if (res.code != 200) return this.$message.error(res.msg)
      this.catelist = res.data
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList ()
    },
    parentCateChanged(){
      this.querInfo.page=1
      this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
      this.getGoodsList()
    },
    //跳转
    goAddGoods(){
      this.$router.push('/goods/addgoods')
    },
    //
    uplodExcel(){
      this.$router.push('/goods/goodslog')
    },
    warehousehandleChange(){
      this.querInfo.page=1
      if (this.warehouseselect.length == 1){
        this.querInfo.w_id=this.warehouseselect[0]
      }else if(this.warehouseselect.length == 2){
        this.querInfo.w_id=this.warehouseselect[0]
        this.querInfo.wp_id=this.warehouseselect[1]
      }else{
        this.querInfo.w_id=''
        this.querInfo.wp_id=''
      }
      this.getGoodsList()
    },
    dataTypeGoodschange(){
      this.querInfo.page=1
      this.getGoodsList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getGoodsList()
    },
    // async exportExcel(){
    //   // var filename = "file.xlsx"; //文件名称
    //   // var data = [[1,2,3],[true, false, null, "sheetjs"],["foo","bar",new Date("2014-02-19T14:30Z"), "0.3"], ["baz", null, "qux"]]; //数据，一定注意需要时二维数组
    //   // var ws_name = "Sheet1"; //Excel第一个sheet的名称
    //   // var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);
    //   // XLSX.utils.book_append_sheet(wb, ws, ws_name); //将数据添加到工作薄
    //   // XLSX.writeFile(wb, filename); //导出Excel
    //   const  {data:res} = await this.$http.get('goods/export')
    //   if (res.code!=200)return this.$message.error(res.msg)
    //   let ws = XLSX.utils.aoa_to_sheet(res.data.data)
    //   let wb = XLSX.utils.book_new()
    //   XLSX.utils.book_append_sheet(wb, ws, res.data.name) // 工作簿名称
    //   XLSX.writeFile(wb, res.data.name) // 保存的文件名
    //   // this.$message.success(res.msg)
    // },
    async goodsStateChanged(row){
      const {data: res} = await this.$http.post(`goods/editStatus`,{
        'ga_id':row.ga_id,
        'ga_status':row.ga_status
      })
      if (res.code != 200) {
        row.ga_status = !row.ga_status
        return this.$message.error('更新状态失败')
      }
      this.$message.success('更新状态成功')
      this.getGoodsList()
    },
    //
    //  editgoods(id){
    //   const {data:res} = await this.$http.get(`goods/find/${id}`)
    //   if (res.code!=200) return this.$message.error(res.msg)
    //   this.editGoodsForm=res.data
    //   console.log(this.editGoodsForm)
    //   this.editGoodsDialogVisible=true
    // },
    editgoods(id){
      this.$router.push({path: "/goods/editgoods", query: {editid: id}})
    },
    addskugoods(id){
      this.$router.push({path: "/goods/addskugoods", query: {editid: id}})
    },
    //删除 qualitylevel/del/12
    async removeGoodsById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该商品, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`goods/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getGoodsList()

    },
    dategoods(id){
      this.$router.push({path: "/goods/dategoods", query: {editid: id}})
    },
    uplodstock(){

      this.$router.push('/goods/upstockimg')

    },
    async exportExcel(){
      return new Promise((resolve, reject) => {
        // console.log(resolve)
        // console.log(reject)
        const {data:res} = this.$http.get('goods/export', {
          responseType: "blob" // 1.首先设置responseType对象格式为 blob:
        })
          .then(
            res => {
              //resolve(res)
              let blob = new Blob([res.data], {
                type: "application/vnd.ms-excel"
              }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
              let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
              // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
              let a = document.createElement("a");
              var date = new Date();
              a.href = url;
              a.download = "商品"+date.getFullYear()+"-"+ (date.getMonth()+1) +"-"+date.getDate() +".xlsx";
              a.click();
              // 5.释放这个临时的对象url
              window.URL.revokeObjectURL(url);
            },
            err => {
              resolve(err.response);
            }
          )
          .catch(error => {
            reject(error);
          });
      });
    },
  }
}
</script>
<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
</style>
